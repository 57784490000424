.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.button-boder{
  border-top-right-radius: .2875rem!important;
  border-bottom-right-radius: .2875rem!important;
}

.ls-toggle-menu .slimScrollDiv div{
  overflow: visible !important;
}

.MuiDialog-root{
  z-index: 6000!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #d67200;
  text-align: center!important;
  font-size: 14px!important
}

.App-link:hover {
  color: #d67200!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Table-header-title{
  font-size: 12px;
}
.Table-list{
  font-size: 10px;
}
.Center{
  align-content: center;
  align-items: center;
  text-align: center;
}

.page-loader{
  z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #eee;
    overflow: hidden;
    text-align: center;
}

.page-loader .loader{
  position: relative;
  top: calc(40% - 30px);
}

a:hover, a:focus {
  color: #fff;
}

.file-wrapper{
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 200px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #777;
  background-color: #FFF;
  background-image: none;
  text-align: center;
  /* border: 2px solid #E5E5E5; */
  -webkit-transition: border-color .15s linear;
  transition: border-color .15s linear;
}

.file-wrapper:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(135deg,#F6F6F6 25%,transparent 25%,transparent 50%,#F6F6F6 50%,#F6F6F6 75%,transparent 75%,transparent);
  background-image: linear-gradient(-45deg,#F6F6F6 25%,transparent 25%,transparent 50%,#F6F6F6 50%,#F6F6F6 75%,transparent 75%,transparent);
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

.file-wrapper, .file-wrapper .file-clear {
  font-family: Roboto,"Helvetica Neue",Helvetica,Arial;
}

.file-wrapper .file-message {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.file-wrapper .file-errors-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(243,65,65,.8);
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s linear .15s,opacity .15s linear;
  transition: visibility 0s linear .15s,opacity .15s linear;
}

.file-wrapper .file-loader {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
  z-index: 9;
}

.file-wrapper input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 5;
}

.form-control-file, .form-control-range {
  display: block;
  width: 100%;
}

.file-wrapper .file-clear {
  /*display: none;*/
  position: absolute;
  opacity: 0;
  z-index: 7;
  top: 10px;
  right: 10px;
  background: 0 0;
  border: 2px solid #FFF;
  text-transform: uppercase;
  font-size: 11px;
  padding: 4px 8px;
  font-weight: 700;
  color: #FFF;
  -webkit-transition: all .15s linear;
  transition: all .15s linear;
}

.file-wrapper, .file-wrapper .file-clear {
  font-family: Roboto,"Helvetica Neue",Helvetica,Arial;
}

.file-wrapper .file-preview {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #FFF;
  padding: 5px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
}

.file-wrapper .file-preview .file-infos .file-infos-inner {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0,-40%);
  transform: translate(0,-40%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  padding: 0 20px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.file-wrapper .file-message span.file-icon {
  font-size: 50px;
  color: #CCC;
}

.file-font-upload:before, .file-wrapper .file-message span.file-icon:before {
  content: '\e800';
}

.file-font:before, .file-wrapper .file-message span.file-icon:before, .file-wrapper .file-preview .file-infos .file-infos-inner p.file-filename span.file-icon:before, [class*=" file-font-"]:before, [class^=file-font-]:before {
  font-family: file;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-left: .2em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.file-wrapper:hover .file-clear, .file-wrapper:hover .file-preview .file-infos {
  opacity: 1;
}

.file-wrapper .file-preview .file-infos {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(0,0,0,.7);
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.file-wrapper:hover .file-preview .file-infos .file-infos-inner {
  margin-top: -5px;
}

.file-wrapper .file-preview .file-infos .file-infos-inner {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0,-40%);
  transform: translate(0,-40%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  padding: 0 20px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.file-wrapper.has-error .file-message .file-error, .file-wrapper.has-preview .file-clear {
  display: block;
}

/** treetable**/
.cp_tree-table {
  /*font-size: 14px;
  color: #040402;*/
  background: #fff;
  /*border: 1px solid rgba(55, 53, 47, 0.2);*/
  
}
.cp_tree-table th {
  background: #04BE5B;
  color: #fff;
  border: 0;
  vertical-align: middle;
  white-space: nowrap;
  padding: .75rem;
}

.cp_tree-table tr th:first-child {
  border-radius: .2875rem 0 0 .2875rem;
}

.cp_tree-table tr th:last-child {
  border-radius: 0 .2875rem .2875rem 0;
}
/* Header */
.cp_tree-table .cp_tree-table_header {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-spacing: 0 5px !important;
 
}

.cp_tree-table .cp_tree-table_header-cell {
  background: #04BE5B;
  width: 100%;
}

.cp_tree-table .cp_tree-table_row{
 
  border-top: 1px solid #dee2e6;
}

.cp_tree-table .cp_tree-table_header:first-child {
  border-radius: .2875rem 0 0 .2875rem;
}

.cp_tree-table .cp_tree-table_header:last-child {
  border-radius: 0 .2875rem .2875rem 0;
}

.cp_tree-table .cp_tree-table_header-cell>.t-right {
  width: 100%;
}

/* Cells */

.cp_tree-table .cp_tree-table_cell {
  box-sizing: border-box;
}

.cp_tree-table .cp_tree-table_cell tr{
  background: #fff;
}

/* Index cell  */

.cp_tree-table .cp_tree-table_cell .toggle-button {
  appearance: none;
  outline: none;
  position: relative;
  font-size: 14px;
  color: #040402;
  border: none;
  padding: 4px 0 4px 15px;
  background-color: transparent;
}

.cp_tree-table .cp_tree-table_cell .toggle-button:not(:disabled) {
  font-weight: 600;
  cursor: pointer;
}

.cp_tree-table .cp_tree-table_cell .toggle-button:not(:disabled)::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  

  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #040402;
  padding: 0;
  vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell .toggle-button.expanded:not(:disabled)::after {
  border-width: 5px 5px 0 5px;
  border-color: #040402 transparent transparent transparent;
}

.cp_tree-table .cp_tree-table_cell>.without-children {
  padding-left: 15px;
}

.cp_tree-table .cp_tree-table_cell>.with-children {
  font-weight: 600;
}

/* Employees cell */

.cp_tree-table .cp_tree-table_cell {
  width: 100%;
  /*height: 72px;*/
}

.cp_tree-table .cp_tree-table_cell td {
  border-top: none;
  padding : 0;
}

.btngroup .active{
  color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.c_list .c_name {
  display: inline-block!important;
  /* margin-left: 0px; */
  font-size: 14px!important;
  color: #d67200!important;
}

.sidebar .user-info .image img{
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

.btn-link{
  display: block;
  color: #222;
  position: relative;
  padding: 15px 10px;
  width: 100%;
  text-align: left;
}

.btn.btn-link, .navbar .navbar-nav>a.btn.btn-link {
  color: #222;
}

.sidebar .menu .list button {
  color: #222;
  position: relative;
  padding: 15px 10px;
}

.sidebar .menu .list button {
  display: block;
}

.sidebar .menu .list button i{
  text-align: center;
}

.sidebar .menu .list button i {
  -moz-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 16px;
}

.sidebar .menu .list button:hover i, .sidebar .menu .list button:active i, .sidebar .menu .list button:focus i {
  -moz-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  -o-transform: rotate(-20deg);
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}

.btn i {
 font-size: 18px!important; 
}

.sidebar .menu .list button {
  color: #222;
  position: relative;
  padding: 15px 10px;
}

.sidebar .menu .list button span {
  margin: 0 0 0 12px;
}

.sidebar .menu .list button {
  color: #222;
  position: relative;
  padding: 15px 10px;
}

.theme-green .sidebar .menu .list button:hover {
  color: #04BE5B;
}

.sidebar .menu .list button:hover, .sidebar .menu .list button:active, .sidebar .menu .list button:focus {
  text-decoration: none !important;
}

.sidebar .menu .list .ml-menu li button {
  font-size: 14px;
  padding-left: 40px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.sidebar .menu .list .ml-menu li button:before {
  font-size: 12px;
  color: #999;
  content: '\f30f';
  position: absolute;
  left: 14px;
  font-family: 'Material-Design-Iconic-Font';
  top: 8px;
}

.cp_tree-table_cell td{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.arrow-right {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}


.ls-toggle-menu .sidebar .menu .list button span {
  display: none;
}

.table.break_table tr td{
  word-break: break-word;
  white-space: normal;
}

.theme-green .navbar-right .navbar-nav>li>button:hover {
  background: #04BE5B;
}

.navbar-right .navbar-nav>li>button:hover {
  color: #fff !important;
}

.navbar-right .navbar-nav>li>button {
  display: inline-block;
}

.navbar-right .navbar-nav>li>button{
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: #222;
  text-align: center;
  width: 50px;
}

.dropdown-menu ul.menu li button{
  -moz-transition: .5s;
  -o-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  display: flex;
  padding: 13px 15px;
  text-decoration: none;
}

.dropdown-menu ul.menu .menu-info p i {
  font-size: 12px!important;
  position: relative;
}

.display-linebreak {
  white-space: pre-line;
}
.rbc-month-row{
  min-height: 80px;
}

.nav-tabs>.nav-item>.nav-link{
  cursor: pointer;
}
.rbc-day-slot .rbc-event {
  min-height: 60px!important;
  position: initial!important;
  width: 100%!important;
}
.rbc-toolbar {
  font-size: 12px!important;
}

.table.c_table {
  table-layout: fixed;
}

.table.c_table tr td, .table.c_table tr th{
  white-space: pre-wrap;
}

.darkGreen {
  background-color: #04BE5B!important;
}

.col-form-label-sm {
  font-size: 11px;
  line-height: 1;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: 4px;
}
.cursor-pointer{
  cursor: pointer;
}
.custom_input button{
  border: 0 !important;
  /* padding: 0!important; */
}

/* .custom_input .bootstrap-select .dropdown-toggle:focus{
  border: 0 !important;
} */

.custom_select .css-yk16xz-control{
  background-color: #b2b6ff!important;
}

.custom_input_bg, .custom_input_bg .css-yk16xz-control{
  background-color: #dfe5cd!important;
}

.checkbox_custom {
  position: unset!important;
  margin-left: -32px!important;
}

.input_custom_disabled:disabled{
  background-color:#ffffff00!important;
}

.border-0{
  border-width: 0!important;
}